import customers from '../storage/data.json'

export const getSimulatorsByCustomer = (customer = '*') => {
  const validCustomers = [
    '*',
    'DUOC',
    'Clínica Alemana',
    'Universidad de Concepción',
    'Sanofi',
    'Universidad de Los Andes',
    'Ministerio de Educación'
  ]
  if (!validCustomers.includes(customer)) {
    throw new Error(`Customer "${customer}" not found.`)
  }

  return customer === '*'
    ? customers
    : customers.filter(c => c.name === customer)
}