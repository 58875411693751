import React from "react";
import { Navbar } from 'react-bootstrap';
import "../../css/nav.css"
const imagesContext = require.context('../../assets/images', true)

function Title() {

    return (
        <div className="nav">
            <div className="superior"></div>
            <Navbar className="logo">
                <Navbar.Brand href="#test" >
                    <img id="logo" alt="Smartraining" src={imagesContext('./header_logo.png').default}></img>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Title;