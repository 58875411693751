import React from 'react'
import '../css/simuladores.css'
import { Card, Button, Container, Row } from 'react-bootstrap'
import { Animated } from 'react-animated-css'
/* import $ from 'jquery' */
import { getSimulatorsByCustomer } from '../storage/getSimulatorsByCustomer'
/* import { getVersionSimulator } from '../storage/getVersionSimluator' */
/* import { getSimulatorType } from '../storage/getSimulatorType' */

const imagesContext = require.context('../assets/images', true)


/* const versions = getVersionSimulator()
console.log(versions) */

export const Simuladores = ({ customer = '*' }) => {
  const customers = getSimulatorsByCustomer(customer)
  return (
    <div>
      <div>

      </div>
      <Animated animationIn='fadeInUp' animationOut='fadeOut' isVisible={true}>
        <div className='contenedor' id='scroll-container'>
          {customers.map((data, index) => {
            /* const imgLocal = !data.logo.includes('http') */
            return (
              <div id={index} className='uni'>
                {data.simuladores.map((info, index) => {
                  const imgLocal = !info.image.includes('http')
                  return (
                    <div id={index} className='card-container'>
                      <div className='scrolleable'>
                        <Container fluid className='cards'>
                          <Row className='carta'>
                            <Card className='card-custom'>
                              <Card.Img
                                className='card-custom-img'
                                variant='top'
                                src={
                                  imgLocal
                                    ? imagesContext(info.image).default
                                    : info.image
                                }
                              />
                              <Card.Body>
                                <Card.Img
                                  fluid
                                  className='card-custom-avatar'
                                  variant='top'
                                  src={
                                    imgLocal
                                      ? imagesContext(data.logo).default
                                      : data.logo
                                  }
                                />
                                <Card.Title>{info.name}</Card.Title>
                                <Card.Text className='description'>
                                  {info.description}
                                </Card.Text>
                                <Button
                                  className='btn-play'
                                  variant='primary'
                                  href={info.url}
                                  target='blank'
                                >
                                  <img
                                    alt='GO'
                                    src={imagesContext('./btnplay.png').default}
                                    height='75%'
                                  ></img>
                                </Button>
                                {/* <p id="ver">{info.version}</p> */}
                              </Card.Body>
                            </Card>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </Animated>
    </div>
  )
}

/* Filter */
/* $('.filter-dropdown ul li').click(function () {
  var selector = $(this).attr('data-filter')
  $('.isotope').isotope({
    filter: selector
  })
}) */
/* Dropdown list */
/* $('.filter-dropdown').click(function () {
  $(this).toggleClass('open')
})
$('.filter-dropdown ul li').click(function () {
  $(this)
    .parent('ul')
    .prev('.selected')
    .children('span.val')
    .text($(this).text())
}) */
