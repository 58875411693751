import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Nav from "./components/Nav/Nav"
import Index from "./Pages/Index"

function App() {
  return (
    <div>
      <BrowserRouter>
        <Nav />
        <Index />
      </BrowserRouter>
    </div>
  );
}

export default App;
