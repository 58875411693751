import React from 'react'
import { Container } from 'react-bootstrap'
import { Simuladores } from '../components/Simuladores'
import '../css/index.css'

function Index () {
  return (
    <div>
      <Container fluid className='body'>
        <Simuladores></Simuladores>
      </Container>
    </div>
  )
}

export default Index
